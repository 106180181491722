import React, { useEffect, useRefm ,useState,useRef } from 'react';
// import Loadericon from '../assets/videos/old_hometop.MP4.lottie.json';
function Homepage() {
  const [loading, setLoading] = useState(true);
  const videoRef = useRef(null);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.muted = true;
      video.play().catch((error) => {
        console.log('Autoplay was prevented:', error);
      });
    }
  }, []);
  useEffect(() => {
    const video = videoRef1.current;
    if (video) {
      video.muted = true;
      video.play().catch((error) => {
        console.log('Autoplay was prevented:', error);
      });
    }
  }, []);
  useEffect(() => {
    const video = videoRef2.current;
    if (video) {
      video.muted = true;
      video.play().catch((error) => {
        console.log('Autoplay was prevented:', error);
      });
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);    
  }, []);
  return (

<>
    
    {loading ? (
        <div id="loadings" className="cs_custom_lodar">
          <div className="inner_div btn_animate">
          <div class="containerddd">
            <div class="dot dot-1"></div>
            <div class="dot dot-2"></div>
            <div class="dot dot-3"></div>
          </div>
          </div>
        </div>
    ) : (<></>)}
    
    <section className="main_home_page_css">
      <div className='main_slider_top'>
      {/* <iframe title="vimeo-player" src="https://player.vimeo.com/video/1009960111?h=8a4aa3550e&autoplay=1&loop=1&muted=1&background=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> */}
        <video
          ref={videoRef}
          preload="auto"
          playsInline
          loop
          controls={false}
          className='backgroundvideo'
        >
          <source src="https://angelpoop.xyz/assetsvideo/hometop.webm" type="video/webm" />
          {/* <source src="https://angelpoop.xyz/assetsvideo/hometop.MP4" type="video/mp4" /> */}
        </video>
        {/* <Lottie className='Lottieicon' animationData={Loadericon} /> */}
   
      </div>
      <div className='section_overlay_about' id='about'>
        <div className='container'>
          <h2>The Legend of AngelPoop</h2>
          <p>TON’s Holy Sh*tstorm From on high, the gods watched memes fall from grace. They summoned their most joyful angel, sent down in the form of AngelPoop to bring the lost meme spirit back to Earth. And so, TON was chosen as the sacred ground for this meme revolution. Together, we’re building the ultimate holy sh*t cult, making TON the meme capital of the universe. Join us and let’s take $APOOP to the stars!</p>
          {/* <p>Together, lets make sure that the Legend of Angelpoop will be etched into the annals of history as a beacon of light in a world shrouded in darkness.</p> */}
        </div>
      </div>
      <div className='about_block' >
      <video
          ref={videoRef1}
          preload="auto"
          playsInline
          loop
          controls={false}
          className='aboutvideo'
          poster="https://angelpoop.xyz/assetsvideo/aboutvideo.webm"
        >
          <source src="https://angelpoop.xyz/assetsvideo/aboutvideo.webm" type="video/webm" />
          <source src="https://angelpoop.xyz/assetsvideo/aboutvideo.mp4" type="video/mp4" />
        </video>
      </div>
      <div className='section_overlay_csscroll'>
        <marquee><img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop </marquee>
      </div>
      <div className='tokefeatures' id='Pooponomics' style={{ backgroundImage: `url('https://angelpoop.xyz/assetsvideo/topbanner.webp')` }}>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='heading_tag'>
                <h2>Pooponomics</h2>
              </div>
            </div>
            <div className='col-lg-5 col-md-12'>
              <ul>
                <li>
                  <img src="https://angelpoop.xyz/assetsvideo/1.webp" />
                  <span>No tax</span>
                </li>
                <li>
                  <img src="https://angelpoop.xyz/assetsvideo/2-2.webp" />
                  <span>Contract renounced</span>
                </li>
                <li>
                  <img src="https://angelpoop.xyz/assetsvideo/3-3.webp" />
                  <span>Liquidity locked</span>
                </li>
                <li>
                  <img src="https://angelpoop.xyz/assetsvideo/superman.webp" />
                  <span>Supply 100B</span>
                </li>
              </ul>
            </div>
            <div className='col-lg-7 col-md-12'>
              <div className='tokefeaturesvideoblock'>
                {/* <video className='innervideo' autoPlay muted loop>
                  <source src={howbuy} type="video/webm" />
                </video> */}
                <img src="https://angelpoop.xyz/assetsvideo/angeltoken.webp" style={{width:"100%"}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='inner_overlay_animation' id='howtobuy'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='inner_data_wallet'>
                <span>********************************** <i class="fa fa-copy"></i></span>
              </div>
            </div>
          </div>
          <h3>How to Buy</h3>
          <div className='row mainrowcenter'>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='inner_data'>
                <img src="https://angelpoop.xyz/assetsvideo/LAPTOP.gif" />
                <div className='inner_content_data'>
                  <h4>Wallet Setup</h4>
                  <p>Download MyTonWallet or wallet of your choice, and create a wallet</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='inner_data'>
                <img src="https://angelpoop.xyz/assetsvideo/money.gif" />
                <h4>Load Up $TON</h4>
                <p>Add some $TON to the wallet so you can swap it for $ANGELPOOP</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='inner_data'>
                <img src="https://angelpoop.xyz/assetsvideo/tracter.gif" />
                <h4>Buy $AngelPoop</h4>
                <p>Swap your $TON for some $ANGELPOOP, and confirm the transaction</p>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-sm-6'>
              <div className='inner_data'>
                <img src="https://angelpoop.xyz/assetsvideo/space.gif" />
                <h4>Seize the Opportunity</h4>
                <p>Embrace the crypto community and seize your opportunity for wealth</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='walletid'></div>
            </div>
          </div>
          </div>
        </div>
        <div className='inner_overlay_animation withteam' id='team'>
          <div className='container'>
          <h3>Our Team</h3>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='teamBox'>
                <img src="https://angelpoop.xyz/assetsvideo/UncleSam1.jpg" />
                <h4>Uncle Sam</h4>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='teamBox'>
                <img src="https://angelpoop.xyz/assetsvideo/Gyro2.jpg" />
                <h4>Gyro</h4>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='teamBox'>
                <img src="https://angelpoop.xyz/assetsvideo/Plumpy3.jpg" />
                <h4>Plumpy</h4>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='teamBox'>
                <img src="https://angelpoop.xyz/assetsvideo/Junior4.png" />
                <h4>Junior</h4>
              </div>
            </div>
          </div>
          {/* <h3 className='teamTitle'>Our Partners </h3>
          <div className='row mainrowcenter'>
            <div className='col-lg-3 col-md-6'>
              <div className='teamBox'>
                <img src="https://angelpoop.xyz/assetsvideo/partner1.png" />
                <h4>Uncle Sam</h4>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='teamBox'>
                <img src="https://angelpoop.xyz/assetsvideo/partner2.png" />
                <h4>Gyro</h4>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className='section_overlay_csscroll section_overlay_csscroll2'>
        <marquee><img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop <img src="https://angelpoop.xyz/assetsvideo/Iconcopy.png" /> AngelPoop </marquee>
      </div>
    </section>
    </>
  );
}
export default Homepage;